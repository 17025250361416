<script>
import { required, maxLength } from "vuelidate/lib/validators";
import validationMessages from "@/components/validations";
import Monitor from "@/services/Monitor";
import Swal from "sweetalert2";

export default {
  components: { validationMessages },

  data() {
    return {
      tenant: "",
      name: "",
      url: "",
      tenants_options: [],
      showModal: false,
      filteredTenants: [],
      tenantDashboard: [] ,
      isFilteringComplete: false,
      submitted: false,
      tryingToSubmit: false,
      showLoader: false,
      csrf_token: localStorage.getItem("csrf_token"),
      error: null,
    };
  },

  validations: {
    tenants: {
      required,
      maxLength: maxLength(8),
    },
    name: {
      maxLength: maxLength(16),
    },
    url: {
      maxLength: maxLength(255),
    },
  },

  methods: {
    closeModal() {
      this.showModal = false;
    },

    async getTenants() {
      try {
        this.showLoader = true;
        const response = await Monitor.getTenants();
        this.tenants_options = response.data.tenants
      } catch (error) {
        console.log('Error fetching tenants:', error);
      } finally {
        this.showLoader = false;
      }
    },

    async getAllSllLinks() {
      try {
        this.showLoader = true;
        const response = await Monitor.getAllSllLinks();
        if (response && response.data.data) {
          this.tenantDashboard = response.data.data.map(item => item.tenant);
        }
      } catch (error) {
        console.error('Error fetching tenant dashboards:', error);
        this.error = error.response?.data?.error || '';
      }
    },

    filterTenants() {
      this.showLoader = true;
      if (!this.tenants_options || !this.tenantDashboard) {
        return [];
      }

      this.filteredTenants = this.tenants_options.filter(tenant => {
        return !this.tenantDashboard.includes(tenant);
      });
      this.isFilteringComplete = true;
      this.showLoader = false;
    },

    async createTenantSSL() {
      this.tryingToSubmit = true;
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.tryingToSubmit = false;
        return;
      }

      let formData = {
        tenant: this.tenant,
        name: this.name,
        url: this.url
      };
      try {
        const response = await Monitor.createTenantSSL(formData);
        if (response.error) {
          this.failedmsg(response.error);
        } else {
          this.successmsg(response.message);
          this.closeModal();
          this.refreshData();
        }
      } catch (error) {
        this.error = error.response?.data?.error || "An error occurred";
        this.failedmsg(this.error);
      } finally {
        this.tryingToSubmit = false;
        this.refreshData();
      }
    },

    successmsg(message) {
      Swal.fire("Success", message, "success");
    },

    failedmsg(message) {
      Swal.fire("Error", message, "error");
    },

    refreshData() {
      this.$emit("onRefresh");
    },

    resetForm() {
      this.tenant = "";
      this.name = "";
      this.url = "";
      this.$v.$reset();
      this.submitted = false;
    },

    async modalShown() {
      this.isFilteringComplete = false;
      await this.getTenants();
      await this.getAllSllLinks();
      this.filterTenants();

      if (this.isFilteringComplete) {
        this.showModal = true;
      }
    }
  },
};
</script>

<template>
  <b-modal @shown="modalShown" id="add_tenant_ssl" size="l" v-model="showModal" @hidden="resetForm" title="Tenant SSL Link" title-class="font-18">
    <form @submit.prevent="createTenantSSL" v-if="!showLoader">
      <div class="row">
        <div v-if="$v.$error" class="text-danger">Complete all fields</div>
        <b-card-text>
          <div class="row">
            <div class="col-sm-12">
              <b-form-group label="Select Tenant" label-for="formrow-tenant-input" class="mb-3">
                <b-form-select
                    v-model="tenant"
                    :options="filteredTenants"
                    :class="{ 'is-invalid': submitted && $v.tenant.$error }"
                    class="form-select"
                ></b-form-select>
                <validationMessages v-if="submitted" :fieldName="'Tenants'" :validationName="$v.tenant"></validationMessages>
              </b-form-group>

              <b-form-group label="Name" label-for="formrow-name-input" class="mb-3">
                <b-form-input v-model.trim="name"></b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Name'" :validationName="$v.name"></validationMessages>
              </b-form-group>

              <b-form-group label="Url" label-for="formrow-url-input" class="mb-3">
                <b-form-input v-model.trim="url"></b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Url'" :validationName="$v.url"></validationMessages>
              </b-form-group>

              <input type="hidden" name="csrf_token" v-model="csrf_token">
            </div>
          </div>
        </b-card-text>
      </div>
    </form>
    <div class="card-body d-flex align-items-center justify-content-center" v-else>
      <b-spinner large></b-spinner>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="createTenantSSL" :disabled="tryingToSubmit || showLoader">
        <b-spinner v-show="tryingToSubmit" small></b-spinner>Submit
      </b-button>
    </template>
  </b-modal>
</template>
