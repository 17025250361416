<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Monitor from "@/services/Monitor";
import ModalEditSslLink from "@/components/modals/ssl/modalEditSslLink";
import ModalAddSslLink from "@/components/modals/ssl/modalAddSslLink";
import Swal from "sweetalert2";

export default {
  components: {
    Layout,
    PageHeader,
    ModalEditSslLink,
    ModalAddSslLink,
  },
  page: {
    title: "Tenant SSL Links",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      error: null,
      tableData: [],
      title: "Tenant SSL Links",
      items: [
        {
          text: "SSL Links",
        },
        {
          text: "Tenant SSL Links",
          active: true,
        },
      ],
      filterInput: {},
      isBusy: false,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "valid_from",
      sortDesc: true,
      tenantSslData: [],
      fields: [
        {
          key: "tenant",
          label: "Tenant",
          visible: true,
        },
        {
          key: "name",
          label: "Name",
          visible: true,
        },
        {
          key: "url",
          label: "Url",
          visible: true,
        },
        {
          key: "ssl_expiration",
          label: "SSL expiration",
          visible: true,
        },
        {
          key: "action",
          label: "Action",
        }
      ],
      filterData: false,
      verifyInput: "",
      loading: false,
    };
  },

  async created() {
    await this.getAllSllLinks();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async getAllSllLinks() {
      try {
        this.filterData = true;
        this.isBusy = true;
        const response = await Monitor.getAllSllLinks();
        this.tableData = response.data.data;
        this.totalRows = this.tableData.length;
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
        this.error = error.response?.data?.error || "An error occurred while fetching data.";
        this.tableData = [];
        this.totalRows = 0;
      }
    },

    // callModalEditTeamDeal(data) {
    //   this.tenantSslData = data;
    //   this.$bvModal.show("edit_tenant_ssl");
    // },

    callModalAddTenantSetup() {
      this.tenantSslData = {}; // Reset tenantSslData
      this.$bvModal.show("add_tenant_ssl");
    },

    removeTenantSetup(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Remove From Tenant Ssl Link!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          Monitor.deleteTenantSSL(id)
              .then((response) => {
                const res = response.data.data || false;
                const error = response.data.error || '';
                if (!res && !error) {
                  this.getAllSllLinks();
                  this.successmsg("Tenant Ssl Link Successfully Removed");
                } else {
                  this.failedmsg("Tenant Ssl Link Removal Failed");
                }
              })
              .catch((error) => {
                this.failedmsg('Fail!', error);
              });
        }
      });
    },
    async updateSSL(item) {
      try {
        this.$set(item, "isLoading", true);

        const payload = {
          tenant: item.tenant,
          name: item.name,
          url: item.url
        };
        const response = await Monitor.updateSSL(payload);

        if (response.data['ssl_expiration'] !== "0000-00-00 00:00") {
          this.$set(item, "ssl_expiration", response.data.update.ssl_expiration);


          if (item.dashboard_version === "0000-00-00 00:00") {
            Swal.fire({
              position: "center",
              icon: "info",
              title: "SSL Expiration is not updated",
              showConfirmButton: false,
              timer: 2000,
            });
          } else {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "SSL Expiration updated successfully",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Failed to update SSL Expiration",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } catch (error) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "An error occurred while updating the SSL Expiration",
          showConfirmButton: false,
          timer: 2000,
        });
      } finally {
        this.$set(item, "isLoading", false);
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toISOString().slice(0, 19).replace('T', ' ');
    },

    getExpirationClass(expirationDate) {
      const currentDate = new Date();
      const expiryDate = new Date(expirationDate);

      const timeDiff = expiryDate - currentDate;
      const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));


      if (daysDiff < 7) {
        return 'badge bg-danger font-size-14';
      } else if (daysDiff < 30) {
        return 'badge bg-warning font-size-14';
      } else {
        return 'badge bg-success font-size-14';
      }
    }

  },
  middleware: "authentication",
};

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :fields="fields"/>
    <div class="row">
      <div class="col-12">
        <div class="card" v-show="filterData">
          <div class="card-body">
            <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light"
               @click="callModalAddTenantSetup">
              <i class="mdi mdi-plus me-2"></i>Add New Tenant SSL Link
            </a>

            <div class="row mt-4">
              <div class="col d-inline-flex align-items-center">
                <h4 class="card-title m-0">Total Tenant SSl Links: {{ totalRows }}</h4>
              </div>
            </div>
            <div class="row mt-4" v-show="filterData">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="deals-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>

            <!-- Table -->
            <div class="table-responsive mb-0 font-size-12" v-show="filterData">
              <b-table
                  :items="tableData"
                  :busy="isBusy"
                  :fields="fields"
                  :visible="fields.visible"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  show-empty
                  empty-text="No Data Found"
              >
                <template v-slot:cell(action)="data">
                  <ul class="list-inline mb-0">
<!--                    <li class="list-inline-item">-->
<!--                      <a href="javascript:void(0);" class="px-2 text-primary" title="Edit Tenant Sll Link" @click="callModalEditTeamDeal(data.item)">-->
<!--                        <i class="uil uil-pen font-size-18"></i>-->
<!--                      </a>-->
<!--                    </li>-->
                    <li class="list-inline-item">
                      <a href="javascript:void(0);" class="px-2 text-danger" title="Remove Tenant Sll Link" @click="removeTenantSetup(data.item.id)">
                        <i class="uil uil-trash-alt font-size-18"></i>
                      </a>
                    </li>
                  </ul>
                </template>
                <template v-slot:cell(ssl_expiration)="data">
                    <span v-if="!data.item.isLoading" :class="getExpirationClass(data.item.ssl_expiration)">
                      {{ formatDate(data.item.ssl_expiration) }}
                    </span>
                                    <span v-else>
                      <b-spinner small></b-spinner>
                    </span>
                  <a href="javascript:void(0);" class="ms-2 text-primary" title="Update SSL" @click="updateSSL(data.item)">
                    <i class="uil uil-refresh font-size-18"></i>
                  </a>
                </template>




                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>

              </b-table>
            </div>
            <!--Table end -->

            <div class="row" v-show="filterData">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- MODALS -->
    <ModalEditSslLink :tenantSslData="tenantSslData" @onRefresh="getAllSllLinks()"></ModalEditSslLink>
    <ModalAddSslLink :tenantSslData="tenantSslData" @onRefresh="getAllSllLinks()"></ModalAddSslLink>
    <!-- /MODALS -->
  </Layout>
</template>

